export default [
  {
    label: 'ID',
    field: 'id',
  },
  {
    label: 'Roles',
    field: 'name',
  },
  {
    label: 'Permissions',
    field: 'permissions',
  },
  {
    label: 'Active',
    field: 'isActive',
  },
  {
    label: 'Action',
    field: 'status',
  },
]
