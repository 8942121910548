<template>
  <div>
    <b-row>
      <b-col>
        <div class="d-flex  justify-content-end flex-wrap mb-2">
          <b-button class="mt-auto" type="button" variant="primary" @click="create">
            <feather-icon icon="PlusIcon" size="14" />
            <span> Create role</span>
          </b-button>
        </div>
      </b-col>
    </b-row>

    <AppTable :rows="roles" :columns="columns" :is-loading="requestInProgress" :is-pagination="false">
      <template #default="{ column, row, formattedRow }">
        <span v-if="column.field === 'permissions'">
          <b-badge v-for="permission in row.permissions" :key="permission" pill variant="light-info">
            {{ permission }}
          </b-badge>
        </span>

        <span v-else-if="column.field === 'isActive'">
          <b-badge :variant="getStatusTheme(row.isActive)">
            {{ getStatusText(row.isActive) }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-else-if="column.label === 'Action'">
          <span>
            <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
              <template v-slot:button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
              </template>

              <b-dropdown-item @click="edit(row)">
                <feather-icon icon="Edit2Icon" class="mr-50" />
                <span>Edit</span>
              </b-dropdown-item>

              <b-dropdown-item @click="deleteUser(row)">
                <feather-icon icon="TrashIcon" class="mr-50" />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>
        <!-- default render cell -->
        <span v-else class="flex-center-align">
          {{ formattedRow[column.field] }}
        </span>
      </template>
    </AppTable>
  </div>
</template>

<script>
import { BButton, BRow, BCol, BBadge, BDropdown, BDropdownItem } from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'

import store from '@/store/index'
import waitRequest from '@/mixins/waitRequest'
import validationError from '@/mixins/validationError'
import columns from './config/tableConfig'
import AppTable from '@/components/AppTable.vue'

export default {
  components: {
    AppTable,
    BButton,
    BRow,
    BCol,
    BBadge,
    BDropdown,
    BDropdownItem,
  },
  mixins: [waitRequest, validationError],
  data() {
    return {
      columns,
    }
  },

  computed: {
    ...mapGetters({
      roles: 'roles/roles',
    }),

    direction() {
      return store.state.appConfig.isRTL
    },
  },

  mounted() {
    this.initState()
  },

  methods: {
    ...mapActions({
      fetchRoles: 'roles/fetchRoles',
      removeRole: 'roles/removeRole',
    }),

    initState() {
      this.fetchRoles()
    },

    statusVariant(status) {
      const statusConfig = {
        isActive: {
          theme: 'light-success',
          text: 'active',
        },
        notActive: {
          theme: 'light-danger',
          text: 'not active',
        },
      }

      return status ? statusConfig.isActive : statusConfig.notActive
    },
    getStatusText(status) {
      const currentStatus = this.statusVariant(status)
      return currentStatus.text
    },
    getStatusTheme(status) {
      const currentStatus = this.statusVariant(status)
      return currentStatus.theme
    },

    create() {
      this.$router.push({ path: '/roles/create' })
    },

    edit({ id }) {
      this.$router.push({ path: `/roles/edit/${id}` })
    },

    deleteUser(role) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Yes, delete '${role.name}'!`,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (!result.value) return

        this.removeRoleHandler(role.id)
      })
    },

    removeRoleHandler(roleId) {
      this.removeRole(roleId)
        .then(() => {
          this.$swal({
            icon: 'success',
            title: 'Deleted!',
            text: 'Your file has been deleted.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
        .catch(this.checkErrors)
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-sweetalert.scss';
</style>

